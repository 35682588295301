import { firestoreAction } from 'vuexfire';
import firebase from 'firebase';

import {
  PAYMENTS,
  paymentsCollection,
  createModel,
} from '@/database/collections';
import { loadingState } from '@/utils/vuex';

export default {
  namespaced: true,
  state: {
    payments: [],
    ...loadingState.state,
  },
  mutations: {
    ...loadingState.mutations,
  },
  actions: {
    bindPaymentsRef: firestoreAction(
      async ({ bindFirestoreRef, rootState, rootGetters }) => {
        const filteredpaymentsCollection = !rootGetters['auth/isAdmin']
          ? paymentsCollection.where('userId', '==', rootState.auth.user?.uid)
          : paymentsCollection;
        return bindFirestoreRef('payments', filteredpaymentsCollection);
      }
    ),

    async savePayment({ commit, rootState }, paymentData) {
      commit('setLoading', true);
      const paymentRequest = createModel(PAYMENTS, {
        ...paymentData,
        userId: rootState.auth.user.uid,
      });

      try {
        await paymentsCollection.add(paymentRequest);
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },

    async updatePayment({ commit }, { id, ...paymentData }) {
      commit('setLoading', true);
      const paymentRequest = createModel(PAYMENTS, paymentData);
      try {
        await paymentsCollection.doc(id).set(paymentRequest);
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },
    async uploadPaymentImage(_, { file, courseId }) {
      return new Promise((resolve, reject) => {
        const storageRef = firebase
          .storage()
          .ref(`payment_images/${courseId}/${file.name}`);
        const uploadTask = storageRef.put(file);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log({ percentage });
          },
          (err) => {
            console.log({ err });
            reject();
          },
          () => {
            resolve(file.name);
          }
        );
      });
    },
    async deletePayment({ commit }, id) {
      commit('setLoading', true);
      try {
        await paymentsCollection.doc(id).delete();
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },
    async downloadPayment({ commit }, { courseId, fileName }) {
      console.log({
        commit,
        fileName,
      });

      return new Promise((resolve, reject) => {
        const storageRef = firebase
          .storage()
          .ref(`payment_images/${courseId}/${fileName}`);
        storageRef
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
