import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#85FFC8',
        secondary: '#9070FF',
        warning: '#FFE685',
        danger: '#FF7070',
      },
      light: {
        primary: '#9070FF',
        secondary: '#9070FF',
        warning: '#FFE685',
        danger: '#FF7070',
      },
    },
  },
});
