import firebase from 'firebase/app';

import router from '@/router';
import { set } from '@/utils/vuex';
import { formatUserSessionResponse } from '@/utils/formatters';
import { usersCollection, createModel, USERS } from '@/database/collections';

export default {
  namespaced: true,

  state: {
    user: null,
    isLoggedIn: false,
  },

  mutations: {
    setUser: set('user'),
  },

  actions: {
    async login({ commit }) {
      const provider = new firebase.auth.OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        tenant: 'cheers.io',
      });

      try {
        const oAuthResponse = await firebase.auth().signInWithPopup(provider);
        const formattedResponse = await formatUserSessionResponse(
          oAuthResponse
        );
        let resultUser = await usersCollection
          .where('email', '==', formattedResponse.email)
          .get();

        if (!resultUser.empty) {
          const userData = resultUser.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))[0];
          /**
           * When the user logs in for the first time the authId should be added to the document and active set to true
           */
          if (!userData.active) {
            const userRequest = createModel(USERS, {
              ...userData,
              active: true,
              authUid: formattedResponse.uid,
            });
            usersCollection.doc(userData.id).set(userRequest);
          }
          commit('setUser', {
            ...formattedResponse,
            id: resultUser.id,
          });
          router.push('/');
        } else {
          // TODO: display a message saying : contact the administrator to get access
        }
      } catch (err) {
        //TODO: Use a snackbar to display the error messages
        console.log({ err });
      }

      return;
    },
    async logout() {
      try {
        await firebase.auth().signOut();
        router.push('/login');
      } catch (err) {
        //TODO: use snackbar component
        console.log('[There is an error loging out]');
      }
    },
  },

  getters: {
    isAdmin({ user }) {
      return !!user.claims?.admin;
    },
    authToken({ user }) {
      return user?.accessToken || user?.idToken;
    },
  },
};
