import db from './index';

// Collection names
export const BUDGETS = 'budgets';
export const COURSES = 'courses';
export const PAYMENTS = 'payments';
export const USERS = 'users';

// Collection references

export const budgetCollection = db.collection(BUDGETS);
export const coursesCollection = db.collection(COURSES);
export const paymentsCollection = db.collection(PAYMENTS);
export const usersCollection = db.collection(USERS);

/**
 * Necessary info from where a model is created
 */

export const COLLECTIONS_DATA = {
  [BUDGETS]: {
    dto: {
      amount: 0,
      employeeId: null,
      year: 2021,
    },
  },
  [COURSES]: {
    dto: {
      budgetId: '',
      courseName: '',
      providerName: '',
      amount: 0,
      startDate: '',
      endDate: '',
      userId: '',
      status: 'pending',
    },
  },
  [PAYMENTS]: {
    dto: {
      courseId: '',
      invoiceAmount: 0,
      invoiceNumber: '',
      status: 'pending', // paid, rejected
    },
  },
  [USERS]: {
    dto: {
      address: '',
      active: false,
      authUid: '',
      email: '',
      lastName: null,
      name: '',
      position: '',
    },
  },
};
/**
 *
 * @param {String} collectionKey
 * @param {Object} modelData data
 * @returns merged objectProperties
 * @description creates an object using a default structure in order to have a pattern for requests (emulating ts interfaces)
 */
export const createModel = (collectionKey, modelData) => {
  if (!COLLECTIONS_DATA[collectionKey]) {
    console.error(
      '[createModel] There is no entry for this collection key please add it in @/database/collections'
    );
    return;
  }

  const defaultDto = COLLECTIONS_DATA[collectionKey].dto;
  const mergedModelData = Object.assign(defaultDto, modelData);
  return mergedModelData;
};
