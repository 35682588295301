import { firestoreAction } from 'vuexfire';

import { usersCollection } from '@/database/collections';
import { loadingState } from '@/utils/vuex';
import { getBudgetsOfUser } from '@/utils/formatters';

export default {
  namespaced: true,

  state: {
    users: [],
    ...loadingState.state,
  },

  mutations: {
    ...loadingState.mutations,
  },

  actions: {
    bindUsersRef: firestoreAction(async ({ bindFirestoreRef }) => {
      return bindFirestoreRef('users', usersCollection);
    }),
  },

  getters: {
    hashUsers: ({ users }, _, rootState) => {
      const { budgets } = rootState.budgets;

      return users
        .filter((user) => user.active)
        .map((user) => ({
          ...user,
          displayName: `${user.name} ${user.lastName}`,
          //TODO: this is temporary until we are able to get the budget from cloud functions backend #CHB-29
          userBudgets: getBudgetsOfUser(budgets, user.authUid),
        }))
        .reduce((acc, { authUid, ...user }) => {
          return {
            ...acc,
            [authUid]: user,
          };
        }, {});
    },
    getUser: (_, getters) => (userId) => getters.hashUsers[userId],
  },
};
