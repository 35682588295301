import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '@/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
      },
      {
        path: 'users',
        component: () =>
          import(/* webpackChunkName: "users" */ '../views/Users.vue'),
      },
      {
        path: 'reports',
        component: () =>
          import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
      },
      {
        path: 'course/:courseId',
        component: () =>
          import(
            /* webpackChunkName: "courseDetail" */ '../views/CourseDetail.vue'
          ),
        props: true,
      },
      {
        path: '',
        redirect: 'dashboard', // default child path
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: (to, _, next) => {
      const isAuthenticated = auth.currentUser;
      if (!isAuthenticated) {
        next();
        return;
      }
      next({ path: '' });
    },
  },
  {
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */ '../views/PageNotFound.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = auth.currentUser;
  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
