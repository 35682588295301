import { firestoreAction } from 'vuexfire';

import {
  COURSES,
  coursesCollection,
  createModel,
} from '@/database/collections';
import { loadingState } from '@/utils/vuex';

export default {
  namespaced: true,
  state: {
    courses: [],
    ...loadingState.state,
  },
  mutations: {
    ...loadingState.mutations,
  },
  actions: {
    bindCoursesRef: firestoreAction(
      async ({ bindFirestoreRef, rootState, rootGetters }) => {
        const filteredCoursesCollection = !rootGetters['auth/isAdmin']
          ? coursesCollection.where('userId', '==', rootState.auth.user?.uid)
          : coursesCollection;
        return bindFirestoreRef('courses', filteredCoursesCollection);
      }
    ),

    async saveCourse({ commit, rootState }, courseData) {
      commit('setLoading', true);
      // Add user id (from auth) here
      const courseRequest = createModel(COURSES, {
        ...courseData,
        userId: rootState.auth.user.uid,
      });

      try {
        await coursesCollection.add(courseRequest);
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },

    async updateCourse({ commit }, { id, ...courseData }) {
      commit('setLoading', true);
      const courseRequest = createModel(COURSES, courseData);
      try {
        await coursesCollection.doc(id).set(courseRequest);
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },
    async deleteCourse({ commit }, id) {
      commit('setLoading', true);

      try {
        await coursesCollection.doc(id).delete();
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },
  },
};
