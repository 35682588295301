import { firestoreAction } from 'vuexfire';

import { BUDGETS, budgetCollection, createModel } from '@/database/collections';
import { httpService } from '@/utils/api';
import { loadingState } from '@/utils/vuex';

export default {
  namespaced: true,

  state: {
    budgets: [],
    ...loadingState.state,
  },

  mutations: {
    ...loadingState.mutations,
  },

  actions: {
    bindBudgetsRef: firestoreAction(
      async ({ bindFirestoreRef, rootState, rootGetters }) => {
        const filteredBudgetCollection = !rootGetters['auth/isAdmin']
          ? budgetCollection.where('employeeId', '==', rootState.auth.user?.uid)
          : budgetCollection;

        return bindFirestoreRef('budgets', filteredBudgetCollection);
      }
    ),

    async getAvailableBudget(_, params = {}) {
      return httpService({
        method: 'get',
        url: 'users-getAvailableBudget',
        params,
      });
    },

    async saveBudget({ commit }, budgetData) {
      commit('setLoading', true);
      const budgetRequest = createModel(BUDGETS, budgetData);

      try {
        await budgetCollection.add(budgetRequest);
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },

    async updateBudget({ commit }, budgetData) {
      commit('setLoading', true);
      const { id, ...budget } = budgetData;
      try {
        await budgetCollection.doc(id).set(budget);
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },

    async deleteBudget({ commit }, id) {
      commit('setLoading', true);

      try {
        await budgetCollection.doc(id).delete();
      } catch (err) {
        console.log({ err });
      }
      commit('setLoading', false);
    },
  },
};
