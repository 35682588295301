import axios from 'axios';
import store from '@/store';
// TODO: create a multi environment logic
axios.defaults.baseURL =
  'https://us-central1-cheers-budget-31582.cloudfunctions.net/';

// Interceptor on requests

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${store.getters['auth/authToken']}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const httpService = (axiosConfig) =>
  axios({
    ...axiosConfig,
  });

export { httpService };
