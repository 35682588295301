/**
 *
 * @param {Object} stateField
 * @returns mutation function
 */
const set = (stateField) => (state, value) => (state[stateField] = value);

/**
 * Reusable loading state logic
 */
const loadingState = {
  state: { isLoading: false },
  mutations: {
    setLoading: set('isLoading'),
  },
};

export { loadingState, set };
