import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { auth } from '@/firebase';

Vue.config.productionTip = false;

/**
 * Import your Firebase configuration and set up the Firebase method onAuthStateChanged.
 * This ensures Firebase initializes before loading the app when a user refreshes a page.
 */
let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
