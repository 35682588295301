import firebase from 'firebase/app';

/**
 * Formatting response coming from oauth
 * @param {Object} response
 * @returns {Object}
 */
const formatUserSessionResponse = async ({ credential = {}, user = {} }) => {
  if (!user) {
    return;
  }
  const { displayName, email, emailVerified, photoURL, providerData, uid } =
    user;
  const { accessToken, idToken } = credential;

  // Getting user claims
  const { claims = { admin: false } } = await firebase
    .auth()
    .currentUser.getIdTokenResult();

  return {
    accessToken,
    claims,
    displayName,
    email: providerData[0]?.email || email,
    emailVerified,
    idToken,
    photoURL,
    uid,
  };
};
/**
 *
 * @param {Array<Object>} budgetList
 * @param {String} userId
 * @returns {Object}
 * @description Returns an object with year and amount of the budget as key value pairs example: { 2020: 3000 }
 */
const getBudgetsOfUser = (budgetList = [], userId) => {
  return budgetList
    .filter((budget) => budget.employeeId === userId)
    .reduce((accum, budget) => {
      accum[budget.year] = { total: budget.amount, budgetId: budget.id };
      return accum;
    }, {});
};

export { formatUserSessionResponse, getBudgetsOfUser };
