import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';

import auth from './auth';
import budgets from './budgets';
import courses from './courses';
import payments from './payments';
import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: vuexfireMutations,
  actions: {},
  modules: {
    auth,
    budgets,
    courses,
    payments,
    users,
  },
});
