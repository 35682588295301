<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex';
import { auth } from '@/firebase';
import { formatUserSessionResponse } from '@/utils/formatters';

export default {
  name: 'App',

  beforeCreate() {
    // TODO: there is some info missing when this callback is performed token is being lost
    auth.onAuthStateChanged(async (user) => {
      const credential = {
        idToken: await user.getIdToken(),
      };
      const formattedUser = await formatUserSessionResponse({
        user,
        credential,
      });
      this.setUser(formattedUser);
    });
  },

  methods: {
    ...mapMutations('auth', ['setUser']),
  },
};
</script>
